import { GetTextEnum } from '@/enums/businessEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import {
  getAreaCodeApi,
  getPrivacyAgreementApi,
  userRealnameApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useMount, useUpdateEffect } from 'ahooks';
import {useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';



// 实名类型
export enum RealNameTypeEnum {
  //身份证
  ID_CARD = '1',
  //驾照
  DRIVING_LICENSE = '2',
  //护照
  PASSPORT = '3',
}



export default function createVerifiedModel() {
  const { t } = useTranslation();
  const { requestUserInfo } = useModel('user') || {};
  const [nationality,setNationality] = useLocalStorage({
    key: 'nationality',
    defaultValue: null,
  })

  // 实名类型
  const [realNameType] = useState([
    {
      text: t('身份证'),
      value: RealNameTypeEnum.ID_CARD,

      tips: [
        {
          label: t('正面身份证'),
          tip: t('请上传正面身份证'),
          id: 1,
          lightImg:require("@/assets/img/verified/light_3.png"),
          darkImg:require("@/assets/img/verified/dark_3.png"),
        },
        {
          label: t('反面身份证'),
          tip: t('请上传反面身份证'),
          id: 2,
          lightImg:require("@/assets/img/verified/light_2.png"),
          darkImg:require("@/assets/img/verified/dark_2.png"),
        },
        {
          label: t('手持证件照'),
          tip: t('请上传手持证件照'),
          id: 3,
          lightImg:require("@/assets/img/verified/light_1.png"),
          darkImg:require("@/assets/img/verified/dark_1.png"),
        },
      ],
    },
    {
      text: t('驾驶证'),
      value: RealNameTypeEnum.DRIVING_LICENSE,

      tips: [
        {
          label: t('正面驾照'),
          tip: t('请上传正面驾照'),
          id: 1,
          lightImg:require("@/assets/img/verified/light_3.png"),
          darkImg:require("@/assets/img/verified/dark_3.png"),
        },
        {
          label: t('反面驾照'),
          tip: t('请上传反面驾照'),
          lightImg:require("@/assets/img/verified/light_2.png"),
          darkImg:require("@/assets/img/verified/dark_2.png"),
          id: 2,
        },
        {
          label: t('手持证件照'),
          tip: t('请上传手持证件照'),
          lightImg:require("@/assets/img/verified/light_1.png"),
          darkImg:require("@/assets/img/verified/dark_1.png"),
          id: 3,
        },
      ],
    },
    {
      text: t('护照'),
      value: RealNameTypeEnum.PASSPORT,

      tips: [
        {
          label: t('护照'),
          tip: t('请上传护照照片页'),
          lightImg:require("@/assets/img/verified/light_3.png"),
          darkImg:require("@/assets/img/verified/dark_3.png"),
          id: 1,
        },
        {
          label: t('手持证件照'),
          tip: t('请上传手持证件照'),
          id: 2,
          lightImg:require("@/assets/img/verified/light_1.png"),
          darkImg:require("@/assets/img/verified/dark_1.png"),
        },
      ],
    },
  ]);
  //实名表单以及提交
  //实名表单以及提交
  const [form, setForm] = useLocalStorage({
    key: 'verified',
    defaultValue: {
      realname: '',
      info1: '',
      info2: '',
      info3: '', // 手持身份证
      certificateNumber: '',
      // 国籍
      nationality: '',
      name: '',
      surname: '',
    },
  });
  const { data: areaCode = [], run: getAreaCodeReq } = useReq(getAreaCodeApi, {
    manual: true,
    onSuccess(res) {
      const th = res?.find?.((item) => item.i18nCode === 'th');
      setForm((prev) => ({ ...prev, nationality: th?.alias }));
    },
  });
  // useMount(() => {
  //   getAreaCodeReq();
  // });

  //  当前实名类型
  const [realNameTypeValue, setRealNameTypeValue] = useState(
    RealNameTypeEnum.ID_CARD,
  );
  // 当前提示和实名信息
  const currentTip = useMemo(() => {
    return realNameType.find((item) => item.value === realNameTypeValue);
  }, [realNameTypeValue, realNameType]);
  useUpdateEffect(() => {
    setForm((prev) => {
      return { ...prev, info1: '', info2: '', info3: '' };
    });
  }, [realNameTypeValue]);

  //获取隐私协议
  const { data: privacyAgreement, run: getPrivacyAgreement } = useReq(
    getPrivacyAgreementApi,
    {
      manual: true,
    },
  );
  useMount(() => {
    requestUserInfo?.({ noFund: true });
    getPrivacyAgreement({
      type: GetTextEnum.SYSTEM_REAL_NAME,
    });
  });
  //隐私协议勾选状态
  const [checked, setChecked] = useState(false);

  const { run: userRealName,loading: subRealnameLoding } = useReq(userRealnameApi, {
    manual: true,
    loadingDefault:false,
    async onSuccess() {
      await requestUserInfo?.({ noFund: true });
      // 删除国籍缓存
      setNationality(null)
      setForm({
        
      })
      toast.success(t('提交成功'));
    },
  });

  return {
    t,
    form,
    setForm,
    userRealName,
    privacyAgreement,
    checked,
    setChecked,
    realNameType,
    realNameTypeValue,
    setRealNameTypeValue,
    currentTip,
    areaCode,
    subRealnameLoding,
    nationality,setNationality,
    getAreaCodeReq

  };
}

export type verifiedFormType = ReturnType<typeof createVerifiedModel>;
